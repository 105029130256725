import Swiper from 'swiper/bundle';
import inView from 'in-view';
import '../../node_modules/magnific-popup/dist/jquery.magnific-popup';

jQuery(document).ready(function ($) {
  $('.glightbox').magnificPopup({
    type: 'iframe',
    iframe: {
      markup:
        '<div class="mfp-iframe-scaler">' +
        '<div class="mfp-close"></div>' +
        '<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
        '</div>',
      patterns: {
        youtube: {
          index: 'youtube.com/',
          id: 'v=',
          src: 'https://www.youtube.com/embed/%id%?autoplay=1',
        },
      },
    },
  });

  $('.magnificGallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function (item) {
        return '';
      },
    },
  });

  var swiper = new Swiper('.test__slider', {
    pagination: {
      el: '.swiper-pagination',
    },
  });
  var gallery = new Swiper('.gallery', {
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 20,
    slidesOffsetBefore: 20,
  });

  // Select all links with hashes
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(':focus')) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });

  $('.burger').on('click', function () {
    $('.overlay').fadeToggle();
    $('body').toggleClass('show-menu');
    $('.sticky-header').removeClass('show');
  });

  if ($(window).width() > 768) {
    //Movement Animation to happen
    const container = document.querySelector('body');

    //Items
    const image1 = document.querySelector('.hero__img--1');
    const image2 = document.querySelector('.hero__img--2');
    const image3 = document.querySelector('.hero__img--3');
    const image4 = document.querySelector('.hero__img--4');
    const caption = document.querySelector('.hero__caption');

    container.addEventListener('mousemove', (e) => {
      let xAxis = (window.innerWidth / 2 - e.pageX) / 50;
      let yAxis = (window.innerHeight / 2 - e.pageY) / 50;
      let xAxis2 = (window.innerWidth / 2 - e.pageX) / 40;
      let yAxis2 = (window.innerHeight / 2 - e.pageY) / 40;
      //Popout
      if (image1) {
        image1.style.transform = `translateY(${xAxis2}px) translateX(${yAxis2}px)`;
      }
      if (image2) {
        image2.style.transform = `translateX(${xAxis}px)`;
      }
      if (image3) {
        image3.style.transform = `translateX(${xAxis}px) translateY(${yAxis}px)`;
      }
      if (image4) {
        image4.style.transform = `translateY(${yAxis2}px) translateX(${yAxis}px)`;
      }
      if (caption) {
        caption.style.transform = `translateX(${yAxis}px) translateY(${xAxis2}px)`;
      }
    });

    const image11 = document.querySelector('.products .hero__img--1');
    const image21 = document.querySelector('.products .hero__img--2');
    const image31 = document.querySelector('.products .hero__img--3');
    const image41 = document.querySelector('.products .hero__img--4');
    const caption1 = document.querySelector('.products .hero__caption');
    container.addEventListener('mousemove', (e) => {
      let xAxis = (window.innerWidth / 2 - e.pageX) / 50;
      let yAxis = (window.innerHeight / 2 - e.pageY) / 50;
      let xAxis2 = (window.innerWidth / 2 - e.pageX) / 40;
      let yAxis2 = (window.innerHeight / 2 - e.pageY) / 40;
      //Popout
      if (image11) {
        image11.style.transform = `translateY(${xAxis2}px) translateX(${yAxis2}px)`;
      }
      if (image21) {
        image21.style.transform = `translateX(${xAxis}px)`;
      }
      if (image31) {
        image31.style.transform = `translateX(${xAxis}px) translateY(${yAxis}px)`;
      }
      if (image41) {
        image41.style.transform = `translateY(${yAxis2}px) translateX(${yAxis}px)`;
      }
      if (caption1) {
        caption1.style.transform = `translateX(${yAxis}px) translateY(${xAxis2}px)`;
      }
    });
  }

  inView('.view')
    .on('enter', (el) => {
      el.classList.add('inView');
    })
    .on('exit', (el) => {
      el.classList.remove('inView');
    });

  $(window).scroll(function () {
    if ($(window).scrollTop() > 150) {
      $('.sticky-header').addClass('show');
    }
    if ($(window).scrollTop() < 150) {
      $('.sticky-header').removeClass('show');
    }
  });
});
